import React from "react";
import styled, { css } from "styled-components";
import Helmet from "react-helmet";
import Layout from "../layouts";
import Stripes from "../components/Stripes";
import GlobalStyles from "../components/GlobalStyles";

class IndexPage extends React.Component {
  state = {
    loading: false,
  };

  handleAssetLoad = () => {
    this.setState({ loading: false });
  };

  render() {
    return (
      <Layout>
        <Root>
          <Helmet>
            <title>Tmzn - Simple time zone conversion app for iPhone</title>
            <link rel="shortcut icon" href="/images/favicon.png" />
          </Helmet>
          <GlobalStyles />

          {!this.state.loading && (
            <React.Fragment>
              <Logo />
              <Ribbon>
                <Stripes />
                <Phone>
                  <Img
                    src="/images/tmp-screenshot.png"
                    onLoad={this.handleAssetLoad}
                    alt="Tmzn"
                  />
                  {/* <Video src="/movies/preview.mp4" /> */}
                  <Text>
                    <h1>Be time zone wise.</h1>
                    <p>
                      Say goodbye to time zone confusion &ndash; stay in sync
                      with Tmzn, the free time zone converter for iPhone. Slide
                      left or right to shift time and instantly update your
                      world locations. Features easy calendar integration to
                      schedule events that work for everyone.
                    </p>
                    <p>
                      <figure>
                        <a href="https://itunes.apple.com/us/app/tmzn-timezone-converter/id1453239207?ls=1&amp;mt=8">
                          <img
                            src="/images/app-store-badge.svg"
                            alt="Download on the App Store"
                          />
                        </a>
                      </figure>
                    </p>
                  </Text>
                </Phone>
              </Ribbon>
            </React.Fragment>
          )}
        </Root>
      </Layout>
    );
  }
}

const Root = styled.div`
  position: relative;
  color: ${(p) => p.theme.colors.text};

  @media (min-width: 768px) {
    height: 100vh;
    overflow: hidden;
    min-height: 700px;
    min-width: ${(p) => p.theme.dimensions.minWidth}px;
  }
`;

const logoAnimationStyles = css`
  opacity: 0;
  animation: fadeInLogo 600ms forwards;
  animation-delay: 2000ms;

  @keyframes fadeInLogo {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Logo = styled.div`
  position: absolute;
  background-image: url("/images/icon.png");
  background-size: cover;

  @media (max-width: ${(p) => p.theme.bp.sm - 1}px) {
    width: 50px;
    height: 50px;
    top: 16px;
    left: 16px;
  }

  @media (min-width: ${(p) => p.theme.bp.sm}px) {
    width: 100px;
    height: 100px;
    top: 30px;
    left: 30px;

    ${(p) => p.theme.animation && logoAnimationStyles};
  }
`;

const phoneAnimationStyles = css`
  opacity: 0;
  animation: fadeInPhone 600ms forwards;
  animation-delay: 200ms;

  @keyframes fadeInPhone {
    from {
      transform: translateY(10%);
      opacity: 0;
    }
    to {
      transform: translateY(5%);
      opacity: 1;
    }
  }
`;

const Phone = styled.div`
  &::before {
    content: "";
    padding-top: 205%;
    display: block;
  }

  @media (max-width: ${(p) => p.theme.bp.sm - 1}px) {
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 5%;
    background-color: ${(p) => p.theme.colors.phoneBg};
    box-shadow: 0 0 50vw 0 ${(p) => p.theme.colors.bg};
    width: 50%;
    margin: auto;
  }

  @media (min-width: ${(p) => p.theme.bp.sm}px) {
    position: relative;
    background-color: ${(p) => p.theme.colors.phoneBg};
    border-radius: 30px;
    width: 32%;
    min-width: 400px;
    left: 100px;
    margin: auto;
    border: 1px solid rgba(255, 255, 255, 0.15);
    box-shadow: 0 0 220px 0 ${(p) => p.theme.colors.bg};
    transform: translateY(5%);

    ${(p) => p.theme.animation && phoneAnimationStyles};
  }
`;

const Img = styled.img`
  position: absolute;
  top: 10%;
  left: 4%;
  width: 92%;
`;

// const Video = styled.video`
//   position: absolute;
//   top: 10%;
//   left: 4%;
//   width: 92%;
// `;

const Ribbon = styled.div`
  @media (max-width: ${(p) => p.theme.bp.sm - 1}px) {
    padding-top: 15vh;

    svg {
      margin-top: 0vh;
      width: 100%;
      left: 0;
      top: 0;
      overflow: hidden;
    }
  }

  @media (min-width: ${(p) => p.theme.bp.sm}px) {
    position: absolute;
    bottom: 0;
    top: 0;
    margin: auto;
    left: 400px;
    right: 0;
    width: 66%;
    min-width: ${(p) => 0.4 * p.theme.dimensions.minWidth}px;
    min-height: ${(p) => p.theme.dimensions.minHeight}px;
    max-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: "";
      display: block;
      padding-top: 100%;
    }
  }

  @media (min-width: ${(p) => p.theme.bp.md}px) {
    left: auto;
    right: 0;
  }
`;

const textAnimationStyles = css`
  opacity: 0;
  animation: fadeIn 600ms forwards;
  animation-delay: 1600ms;

  @keyframes fadeIn {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

const Text = styled.div`
  p {
    opacity: 0.66;
    line-height: 1.5;
  }

  p img {
    width: 160px;
  }

  figure {
    margin: 0;
  }

  @media (max-width: ${(p) => p.theme.bp.sm - 1}px) {
    position: absolute;
    left: -25%;
    right: -25%;
    width: 150%;
    margin-top: 30px;
    padding-bottom: 5vh;
  }

  @media (min-width: ${(p) => p.theme.bp.sm}px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 10vh;
    position: absolute;
    width: 700px;
    padding-right: 120px;
    padding-left: 30px;
    right: 100%;
    margin: auto;
    min-height: 30%;
    top: 0;
    bottom: 0;
    z-index: 1;

    h1 {
      font-size: 64px;
      letter-spacing: -1.5px;
      font-weight: 600;
      line-height: 1;
      margin: 0;
    }

    p {
      font-size: 18px;
      font-weight: 400;
      margin: 1.5em 0 0;

      &:last-child {
        opacity: 1;
        font-weight: 500;

        a {
          text-decoration: underline;
          color: inherit;
        }
      }
    }

    ${(p) => p.theme.animation && textAnimationStyles};
  }

  @media (min-width: ${(p) => p.theme.bp.lg}px) {
    width: 760px;

    h1 {
      font-size: 72px;
    }

    p {
      font-size: 22px;
    }
  }
`;

export default IndexPage;
