import React from "react";
import styled, { css } from "styled-components";
import theme from "../theme";

const STROKE_WIDTH = 4.2;
const OFFSET = 1.5;
const Y2_DEFAULT = -25;
const Y1_DEFAULT = 125;
const X1_DEFAULT = -40;
const X2_DEFAULT = 140;

const Stripes = () => (
  <Svg viewBox="0 0 100 100">
    <SvgLine
      x1={X1_DEFAULT}
      x2={X2_DEFAULT}
      y1={Y1_DEFAULT - STROKE_WIDTH * OFFSET}
      y2={-STROKE_WIDTH * OFFSET + Y2_DEFAULT}
      strokeWidth={STROKE_WIDTH}
      strokeLinecap="round"
      stroke={theme.colors.color1}
      delay={0}
    />
    {/* reverse this svg line's animation by inverting the path */}
    <SvgLine
      x1={X2_DEFAULT}
      x2={X1_DEFAULT}
      y1={Y2_DEFAULT}
      y2={Y1_DEFAULT}
      strokeWidth={STROKE_WIDTH}
      strokeLinecap="round"
      stroke={theme.colors.color2}
      delay={100}
    />
    <SvgLine
      x1={X1_DEFAULT}
      x2={X2_DEFAULT}
      y1={Y1_DEFAULT + STROKE_WIDTH * OFFSET}
      y2={STROKE_WIDTH * OFFSET + Y2_DEFAULT}
      strokeWidth={STROKE_WIDTH}
      strokeLinecap="round"
      stroke={theme.colors.color3}
      delay={200}
    />
  </Svg>
);

const Svg = styled.svg`
  position: absolute;
  width: calc(100% + 150px);
  height: calc(100% + 150px);
  top: -50px;
  left: -50px;
  overflow: visible;
`;

const svgLineAnimationStyles = css`
  stroke-dasharray: 250;
  stroke-dashoffset: 250;
  animation: dash 500ms forwards;
  animation-delay: ${(p) => p.delay + 500}ms;

  @keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
  }
`;

const SvgLine = styled.line`
  ${(p) => p.theme.animation && svgLineAnimationStyles};
`;

export default Stripes;
